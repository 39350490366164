body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@font-face {
  font-family: 'mailartrubberstampregular';
  src: url('static/MAILR___-webfont.ttf');
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4, h5 {
  font-family: 'mailartrubberstampregular';
}

p {
  font-family: 'muli';
}